/* CardList.css */

.cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.CardListImage {
    border-radius: 10px;
}

.StickyBarContainer {
    display: flex;
    justify-content: flex-start;
}

.StickyBarContainer:nth-child(n+2) {
    margin-left: 10px;
}

/* Parent container styles */
.parent-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;


}

.dropdownFilters {
    color: #828282 !important;
    background-color: #FFFFFF;
    width: 150px;
    margin-left: 10px;
    max-height: 50px;
}


.dropdownFiltersSearch {
    color: #828282 !important;
    background-color: #FFFFFF;
    width: 150px;
    height: 50px;
    border-radius: 4px 0px 0px 4px !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #DADADA !important;
}


.filterButton {
    color: #828282 !important;
    background-color: #FFFFFF !important;
    margin-left: 10px;
    height: 50px;
}

.DeckList {
    position: sticky;
    min-width: 350px;
    overflow-y: auto;
    top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    /* Adjust width based on the CSS variable */
    width: auto;
    /* Subtract padding from both sides */
    max-height: 85vh;
    overflow-x: hidden;
    border-radius: 8px;
    background-color: #222326;
    border: 1.5px black solid;
}

.css-1w8d998-MuiList-root {
    height: 70vh;
    overflow-y: scroll;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: #222326 !important;
}

.settingsLogo {
    width: 35px;
    margin-left: 10px;
    color: white;

    &:hover {
        cursor: pointer;
        filter: brightness(75%);
    }
}

.sticky-bar {
    position: sticky;
    top: 0;
    background-color: #222326;
    padding-top: 10px;
    z-index: 10;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    border-top: 1px solid #2b2c30
}

.StickyBarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}



.sticky-bar input[type="text"] {
    width: 150px;
    height: 50px;
    padding: 8px;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #ccc;
    outline: none;
}


.sticky-bar select {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;

    padding: 8px;
    background-color: #655a3f;
    border: 0px;
    color: white;

}

.sticky-bar button {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.addOne {
    display: none;
}

.cardContainer:hover .addOne {
    display: flex;
}

.cardImageContainer img {
    display: block;
}

.card-list-container {
    position: relative;
    box-sizing: border-box;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}


.card-item {
    position: relative;
    width: 250px;
    margin: 1px;
    cursor: pointer;
    overflow: hidden;
}

.card-item img {
    width: 100%;
    height: auto;
    object-fit: contain;
    image-rendering: optimizeQuality;
    caret-color: transparent;
}

.buttons {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Ensure buttons span the entire card */
    height: 100%;
    /* Ensure buttons span the entire card */
    display: flex;
    justify-content: space-between;
    /* Adjust as needed */
    align-items: center;
    /* Adjust as needed */

}

.cardAA {
    position: absolute;
    left: 82.5%;
    top: 20%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    transform: translate(-50%, -50%);
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 40px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.cardFlip {
    position: absolute;
    left: 82.5%;
    top: 30%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 40px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}


.cardView {
    position: absolute;
    left: 82.5%;
    top: 40%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 40px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.footer {
    color: white;
}


.modalAAButton {
    position: absolute;
    right: 0px;
    top: 10%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 40px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.button {
    background-color: #655a3f !important;
    color: white !important;
    padding: 5px 15px;
    border-radius: 5x;
    outline: 0;
    font-weight: 500;
    margin: 0px 0px 0px 10px;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
    transition: ease background-color 250ms;

    &:hover {
        background-color: #2a251b !important;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}



@media screen and (max-width: 1830px) {
    .sticky-bar {
        display: flex;
        flex-direction: column;
    }

    .builderMenu {
        display: flex;
        flex-wrap: wrap;
    }
}


@media screen and (max-width: 1430px) {


    .builderMenu button {
        margin-top: 10px;
    }



}

@media screen and (max-width: 769px) {
    .addOne {
        display: flex;
    }

    .cards-wrapper {
        justify-content: center;
    }
}

.footer {
    text-align: center;
}