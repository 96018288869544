* {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* App.css */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Set the height of the app container */
}

.content-container {
  width: 100%;
}

.deck-builder-container {
  box-sizing: border-box;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 100px;
  overflow-y: auto;
  /* Enable scrolling for overflow content */
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-height: 25vh !important;
}

/* .css-1ty026z {
  display: flex;
  justify-content: center;
}
  */


.deck-builder-container {
  width: 40vw;
  background-image: url('/public/assets/egg-bg-1.jpg');
}

/* App.css */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 25;
}


body {
  background-image: url('/public/assets/egg-bg-1-black.jpeg');
  background-size: contain;
  background-color: #1E1F21;
  margin: 0;
  color: #ffff;
  /* Enable scrolling */
}

@media screen and (max-width: 768px) {

  .parent-container {
    width: 100vw;
  }

  .leader-card-container img {
    width: 22vw;
  }
}