tspan {
    fill: #dadada;
}

.css-1qdzy9k-MuiBarElement-root {
    fill: #dadada !important;
}

.css-yrgrvu {
    fill: #dadada !important;
}