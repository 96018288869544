/* DeckBuilder.css */
.selected-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.selected-card-item {
    width: 150px;
    /* Adjust the width of each card item as needed */
    margin: 5px;
    text-align: center;
    position: relative;
    cursor: pointer;
    /* Added for absolute positioning within the card item */
}

.card-image-container {
    width: 150px;
    border-radius: 10px;
}

.selected-card-item img {
    width: 100%;
    height: auto;
    /* Adjust the height of the card image as needed */
    object-fit: fill;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    caret-color: transparent;
}

.card-count {
    position: absolute;
    top: 5%;
    left: 85%;
    font-size: 18px;
    color: white;
    background-color: white;
    color: black;
    padding: 0px 5px 0px 5px;
    border-radius: 15px;
    font-weight: 700;
    transition: ease background-color 250ms;
    width: 15px;
    --shadow-offset: 2;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;
    text-align: center;
}

.selected-card-item p {
    margin: 5px 0;
    caret-color: transparent;
    white-space: nowrap;
    /* Prevent line breaks */
    overflow: hidden;
    text-overflow: ellipsis;
    /* Display ellipsis (...) for overflow text */
}

.deckStatsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.deck-builder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* Set a background color if needed */
    overflow-y: auto;
    color: #655a3f;
    background-repeat: repeat;
    background-size: cover;
}

.deck {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
}

.removeLeader {
    position: absolute;
    top: 40%;
    left: 85%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 30px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}


.viewLeader {
    position: absolute;
    right: 0;
    top: 60px;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 40px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}


.flipLeader {
    position: absolute;
    right: 0;
    top: 60%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 40px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}


.cardView-db {
    font-size: 12px;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 35px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    position: absolute;
    right: -7.3205px;
    top: 21.9615px;
    border-radius: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease 0s;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }

}

.removeAll {
    position: absolute;
    top: 60%;
    left: 85%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 30px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.removeOne {
    position: absolute;
    top: 40%;
    left: 85%;
    font-size: 12px;
    color: white;
    background-color: #655a3f;
    color: white;
    padding: 3px;
    border-radius: 15px;
    font-weight: 500;
    transition: ease background-color 250ms;
    width: 30px;
    --shadow-offset: 2.5;
    border: 2px solid #000;
    box-shadow: 0px calc(var(--shadow-offset, 0) * 1px) 0 0 #333;
    cursor: pointer;
    outline: transparent;

    &:hover {
        background-color: black;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.deckBuilderList {
    padding-right: 15px;
    padding-bottom: 10px;
    position: relative;
}

.addOne {
    position: absolute;
    border-radius: 100%;
    background: white;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: 1px solid black;

    &:hover {
        background-color: black;
        color: white;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.deckBuilderList:hover .addOne {
    display: flex;
}

.leader-card:hover .addOne {
    display: flex;
}

.cardCount {
    position: absolute;
    border-radius: 100%;
    background: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px solid black;
}

.buttonSVG {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: inherit;
}

.button {
    background-color: #655a3f !important;
    color: white !important;
    padding: 5px 15px;
    border-radius: 5x;
    outline: 0;
    font-weight: 500;
    margin: 0px 0px 0px 10px;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
    transition: ease background-color 250ms;

    &:hover {
        background-color: #2a251b !important;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.cardButtons {
    position: relative;
}

.deck-stats {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: column;
    width: 300px;
    /* Adjust the height as needed */
}

.leader-card-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.leader-sub-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

}

.leader-card {
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
}

.barGraph {
    width: 100%;
    margin-top: 10px;
    display: flex;
}

.barContainer {
    display: flex;
    align-items: center;
    height: 30px;
    margin-bottom: 5px;
}

.bar {
    height: 100%;
    background-color: #007bff;
}





.leader-card-container img {
    width: 10vw;
}

.right-side-container {
    /* Add styles for the right side container */
    display: flex;
    /* Use flexbox layout */
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Align items to the start of the container */
    justify-content: center;
}

.cardTypesList {
    white-space: normal;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.rarityTitle {
    padding-right: 10px;
    color: rgb(255, 255, 255);
}

.rarityList {
    display: flex;
    background-color: #25262A;
    margin: 5px;
    border: 1px solid black;
    justify-content: center;
    padding: 2px;
    color: white;
    border-radius: 5px;


}

.builderHeader img {
    width: 2vw;
    padding: 10px;
}

.builderHeader {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.exceeded-limit {
    color: white;
    background-color: red;
    /* Or apply any other styling or animation for flashing */
    animation: flash 0.75s infinite;
}

.highlight-yellow {
    color: white;
    background-color: rgb(197, 128, 0);
    animation: flash 1.25s infinite;
}

@keyframes flash {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }

    100% {
        opacity: 1;
    }
}

.sticky-bar-db {
    position: sticky;
    top: 0;
    background-color: #222326;
    padding-top: 10px;
    z-index: 10;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    border-top: 1px solid #2b2c30;
}

.sticky-bar-db button {
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.exportText {
    width: 100%;
    height: 50vh;
}

.importText {
    width: 100%;
    height: 50vh;
}

.accordion {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;

}

.accordion>* {
    margin-right: 10px;
}

.accordion-section.active {
    color: red;

}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .leader-card img {
        width: 20vw;
    }
}


@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .leader-card img {
        width: 15vw;
    }
}

@media screen and (max-width: 1023px) {
    .deck {
        flex-direction: column !important;
        width: auto !important;
        align-items: center;
    }

    .deck-stats {
        max-width: 95% !important;
        width: auto;
    }
}

@media screen and (max-width: 769px) {
    .addOne {
        display: flex;
    }

    .cards-wrapper {
        justify-content: center;
    }

    .selected-cards-container {
        justify-content: center;
    }
}