.pdf-modal {
    display: none;
    /* Initially hide the modal */
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* Disable scrolling */
    background-color: #000000e6;
    /* Semi-transparent background */
}

.pdf-modal.open {
    display: block;
    /* Show the modal when isOpen is true */
}

.pdf-modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    border: 1px solid #888;
    width: 50%;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
}