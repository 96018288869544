.custom-modal-image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
}

/* Custom modal overlay styles */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000e6;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.css-cwpu7v {
    overflow-y: visible !important;
    background-color: #00000000 !important
}

/* Custom modal content styles */
.custom-modal-content {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}

.custom-modal-content img {
    width: 90%;
    border-radius: 20px;
}


.cardInformation {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 50%;
    margin-left: 30px;
    color: white;
}

.cardInformationName {
    font-size: 2em;
    font-weight: 700;
}

.cardInformationEffect {
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 10px;
}

.cardInformationList {
    padding-left: 20px;
    margin: 0;
}

.cardInformationBullet::marker {
    color: #F7F5C1;

}

.cardInformationLabel {
    color: #F7F5C1;
    font-size: 1em;
    font-weight: 500;
}

.cardInformationNumber {
    font-size: 0.75em;
    font-style: italic;
    color: #dadada;
    line-height: 2;
}

.cardBullets {
    margin-top: 10px;
}

.cardBullets>ul {
    padding-left: 20px;
}



/* Add media query for smaller viewports */
@media screen and (max-width: 768px) {
    .custom-modal-content {
        flex-direction: column;
        /* Stack content vertically */
        align-items: center;
        /* Center align content */
        width: 60vw;
        /* Adjust width for smaller screens */
    }

    .custom-modal-image {
        width: 100%;
    }


    .cardInformation.show {
        display: block;
        /* Show card information when toggled */
    }
}



@media screen and (min-width: 769px) and (max-width: 1024px) {
    .custom-modal-content {
        width: 70vw;
    }
}


@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .custom-modal-content {
        width: 50vw;
    }
}