.overlayDeckListStats {
    width: 100%;
}

.barGraphModal {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.css-1ab2xsx {
    background-color: #1E1F21 !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: #1E1F21 !important;
}

.DrawerWindow {
    background-color: #1E1F21;
    width: 40vw;
}

@media screen and (max-width: 768px) {
    .DrawerWindow {
        background-color: #1E1F21;
        width: auto;
    }
}