.nav-bar {
    background-color: #25262A;
    padding-top: 5px;
    padding-bottom: 5px;
}

.NavContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.LeftNavContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.RightNavContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#NavButton {
    color: #dadada;
    text-transform: none !important;
    font-size: 16px;
    margin-left: 25px;

    &:hover {
        background-color: #25262A !important;
        text-decoration: underline solid #F7F5C1;
        color: #ffffff
    }

    overflow: hidden;
    /* Hide any overflowing text */
    text-overflow: ellipsis;
    /* Add ellipsis for overflow text */
    white-space: nowrap;
    /* Prevent text from wrapping */
}


.logo {
    width: 100px;
    height: 100%;
    cursor: pointer;
}


.SocialIcon {
    width: 40px;
    margin-left: 20px;

    &:hover {
        cursor: pointer;
        filter: brightness(75%);
    }
}


.button {
    background-color: #655a3f !important;
    color: white !important;
    padding: 5px 15px;
    border-radius: 5px;
    outline: 0;
    font-weight: 500;
    text-transform: none !important;
    margin: 0px 0px 0px 10px !important;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
    transition: ease background-color 250ms;

    &:hover {
        background-color: #2a251b !important;
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.colorFilter {
    margin: 0px 0px 0px 10px;
}


@media screen and (max-width: 769px) {
    .LeftNavContainer {
        flex-direction: row;
    }

    .NavContainer {
        flex-direction: column;
    }
}

@media screen and (max-width: 450px) {
    .LeftNavContainer {
        flex-direction: column;
    }

    .StickyBarContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}